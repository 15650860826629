.video-frame {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    max-width: 1000px;
    margin: -100px auto 0 auto;

  @media (max-width: 900px) {
    margin: 0 auto;
  }
}