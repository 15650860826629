// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Utilities
@import "custom/utils/fonts.scss";

// Partials
@import "custom/partials/_highlight.scss";
@import "custom/partials/_ascii.scss";
@import "custom/partials/_video.scss";
@import "custom/partials/_hero.scss";
@import "custom/partials/_security.scss";

// Pages
@import "custom/pages/content";

html, 
body {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

h1, h2, h3, h4 {
  font-weight: 500;
}

h5, h6 {
  font-weight: 500;
}

a {
  font-weight: 500;
  transition: all .1s ease-in-out;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.section {
  border: 1px dashed #fff;
}

main,
footer {
  margin: auto;
}