.ascii {
  color: white;
  font-family: "Source Code Pro";
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;

  &.ascii-sm {
    font-size: 15px;
    line-height: 19px;
  }

  &.ascii-xs {
    font-size: 11px;
    line-height: 16px;
    font-weight: 900;
  }

  &.ascii-gray {
    @apply text-gray-400;
  }
}

// Inverse of what the usual media query is, i.e. not mobile-first
@media (max-width: 640px) {
  .xs\:ascii-sm {
    font-size: 15px;
    line-height: 19px;
  }
}
