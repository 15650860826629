.hero-back {
  background-image: url(/_assets/img/hero-left.png),
  url(/_assets/img/hero-right.png);
  background-position: left center,
  right center;
  background-repeat: no-repeat,
  no-repeat;
  background-size: calc(722px * .35) calc(1706px * .35),
  calc(734px * .35) calc(1559px * .35);

  @media (max-width: 500px) {
    padding-bottom: 0;
    background: none;
  }

  &.hero-full-height {
    min-height: calc(100vh - 280px);
    padding-top: calc(100vh*.2);

    @media (max-width: 768px) {
      min-height: auto;
      padding-top: 4rem;
    }
  }
}