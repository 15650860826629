.content-page {
    color: #fff;

    a {
        font-weight: 700;
        color: white;
        text-decoration: underline;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
        font-family: 'Inter', sans-serif;
        margin-bottom: 1rem;
    }

    h1 {
        margin-top: 3rem;
        font-size: 3rem;
    }

    h2 {
        margin-top: 2.5rem;
        font-size: 1.8rem;
        line-height: 2rem;
    }

    h3 {
        margin-top: 2rem;
        font-size: 1.2rem;
        line-height: 1.4rem;
    }

    h4 {
        margin-top: 2rem;
        font-size: 1rem;
        line-height: 1.2rem;
    }

    h5 {
        margin-top: 1rem;
        font-size: 0.8rem;
        line-height: 1rem;
    }

    h6 {
        margin-top: 1rem;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    p, ol, ul, blockquote, pre, table, figure, div.callout {
        margin: 1rem 0;
        font-size: 1rem;
    }

    ol, ul {
        margin-left: 1.4rem;
        font-size: 1rem;

        li {
            margin-bottom: 1rem;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;
    }

    blockquote {
        border-left: 2px solid #ccc;
        padding: .1rem 0 .1rem 1rem;
        font-style: italic;
        font-size: 1rem;
        font-family: 'Source Code Pro', monospace;
    }

    pre {
        font-size: 1rem;
        padding: 0.6rem 1rem;
        background: #222;
        width: 100%;
        white-space: pre-wrap;

        code {
            padding: 0px;
        }
    }

    code {
        font-family: 'Source Code Pro', monospace;
        font-size: 1rem;
        background: #222;
        color: #fff;
        padding: .05rem .3rem .06rem .3rem;
    }

    figure {
        figcaption {
            font-size: 1rem;
            font-style: italic;
            color: #999;
        }
    }

    div.callout {
        padding: 0.6rem 1rem;
        display: flex;
        align-items: center;

        div.callout-emoji {
            display: relative;
            float: left;
        }

        p {
            margin: .5rem .5rem .5rem 1rem;
            font-size: 1rem;
        }
    }

    img {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

}