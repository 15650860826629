@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "ASCII";
  src: url(/_assets/fonts/ms-pgothic.ttf);
}
.highlight {
  color: #bbb;
}
.highlight.highlight-light {
  color: #888;
}

.highlight-underline {
  position: relative;
  z-index: 0;
}
.highlight-underline::after {
  content: "";
  position: absolute;
  bottom: -0.35rem;
  left: -23%;
  right: 0;
  height: 40%;
  width: 125%;
  z-index: -1;
  background-image: url("/_assets/img/highlight.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.highlight-underline.highlight-underline-light::after {
  background-image: url("/_assets/img/highlight-light.svg");
}

.text-5xl .highlight-underline::after {
  bottom: -0.2rem !important;
}

.text-4xl .highlight-underline::after {
  bottom: -0.2rem !important;
}

.text-3xl .highlight-underline::after {
  bottom: -0.2rem !important;
}

.ascii {
  color: white;
  font-family: "Source Code Pro";
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
}
.ascii.ascii-sm {
  font-size: 15px;
  line-height: 19px;
}
.ascii.ascii-xs {
  font-size: 11px;
  line-height: 16px;
  font-weight: 900;
}
.ascii.ascii-gray {
  @apply text-gray-400;
}

@media (max-width: 640px) {
  .xs\:ascii-sm {
    font-size: 15px;
    line-height: 19px;
  }
}
.video-frame {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  max-width: 1000px;
  margin: -100px auto 0 auto;
}
@media (max-width: 900px) {
  .video-frame {
    margin: 0 auto;
  }
}

.hero-back {
  background-image: url(/_assets/img/hero-left.png), url(/_assets/img/hero-right.png);
  background-position: left center, right center;
  background-repeat: no-repeat, no-repeat;
  background-size: 252.7px 597.1px, 256.9px 545.65px;
}
@media (max-width: 500px) {
  .hero-back {
    padding-bottom: 0;
    background: none;
  }
}
.hero-back.hero-full-height {
  min-height: calc(100vh - 280px);
  padding-top: 20vh;
}
@media (max-width: 768px) {
  .hero-back.hero-full-height {
    min-height: auto;
    padding-top: 4rem;
  }
}

.security-list li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0.8rem 0;
}

.content-page {
  color: #fff;
}
.content-page a {
  font-weight: 700;
  color: white;
  text-decoration: underline;
}
.content-page h1, .content-page h2, .content-page h3, .content-page h4, .content-page h5, .content-page h6 {
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin-bottom: 1rem;
}
.content-page h1 {
  margin-top: 3rem;
  font-size: 3rem;
}
.content-page h2 {
  margin-top: 2.5rem;
  font-size: 1.8rem;
  line-height: 2rem;
}
.content-page h3 {
  margin-top: 2rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.content-page h4 {
  margin-top: 2rem;
  font-size: 1rem;
  line-height: 1.2rem;
}
.content-page h5 {
  margin-top: 1rem;
  font-size: 0.8rem;
  line-height: 1rem;
}
.content-page h6 {
  margin-top: 1rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
}
.content-page p, .content-page ol, .content-page ul, .content-page blockquote, .content-page pre, .content-page table, .content-page figure, .content-page div.callout {
  margin: 1rem 0;
  font-size: 1rem;
}
.content-page ol, .content-page ul {
  margin-left: 1.4rem;
  font-size: 1rem;
}
.content-page ol li, .content-page ul li {
  margin-bottom: 1rem;
}
.content-page ol {
  list-style-type: decimal;
}
.content-page ul {
  list-style-type: disc;
}
.content-page blockquote {
  border-left: 2px solid #ccc;
  padding: 0.1rem 0 0.1rem 1rem;
  font-style: italic;
  font-size: 1rem;
  font-family: "Source Code Pro", monospace;
}
.content-page pre {
  font-size: 1rem;
  padding: 0.6rem 1rem;
  background: #222;
  width: 100%;
  white-space: pre-wrap;
}
.content-page pre code {
  padding: 0px;
}
.content-page code {
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  background: #222;
  color: #fff;
  padding: 0.05rem 0.3rem 0.06rem 0.3rem;
}
.content-page figure figcaption {
  font-size: 1rem;
  font-style: italic;
  color: #999;
}
.content-page div.callout {
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
}
.content-page div.callout div.callout-emoji {
  display: relative;
  float: left;
}
.content-page div.callout p {
  margin: 0.5rem 0.5rem 0.5rem 1rem;
  font-size: 1rem;
}
.content-page img {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

html,
body {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

h1, h2, h3, h4 {
  font-weight: 500;
}

h5, h6 {
  font-weight: 500;
}

a {
  font-weight: 500;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.section {
  border: 1px dashed #fff;
}

main,
footer {
  margin: auto;
}